import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Contact from "../Contact/Contact";
import image from "../../assets/aboutus.png";
import "./About.css";
import { useTranslation } from "react-i18next";
const About = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Navbar />
      <div className="about_page">
        <h1>{t("aboutOurCompany")}</h1>
        <div className="about_information">
          <img src={image} alt="" />
          <p>{t("aboutOurCompanyInfo")}</p>
        </div>
      </div>
      <Contact />
      <Footer />
    </div>
  );
};

export default About;
