import React from "react";
import { motion } from "framer-motion";
import "./Main.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Main = () => {
  const { t } = useTranslation();
  const textAnimation = {
    hidden: {
      x: -100,
      opacity: 0,
    },
    visible: (custom) => ({
      x: 0,
      opacity: 1,
      transition: { delay: custom * 0.2 },
    }),
  };
  const buttonVariants = {
    rest: { scale: 1 },
    hover: { scale: 1.1 },
    pressed: { scale: 0.95 },
  };
  return (
    <div className="section">
      <div className="middle">
        <motion.div
          initial="hidden"
          whileInView="visible"
          className="container"
        >
          <motion.p custom={1} variants={textAnimation}>
            Farovon
          </motion.p>
          <motion.p custom={2} variants={textAnimation}>
            Maksimum
          </motion.p>
          <Link to={"/about"} className="abt_btn">
            <motion.button
              variants={buttonVariants}
              whileHover="hover"
              whileTap="pressed"
              className="btn_main"
            >
              {t("learnMore")}
              <div class="button__horizontal"></div>
              <div class="button__vertical"></div>
            </motion.button>
          </Link>
        </motion.div>
      </div>
    </div>
  );
};

export default Main;
