import React, { useContext } from "react";
import style from "./Navbar.module.css";
import logo from "../../../assets/logo.png";
import { Link } from "react-router-dom";
import { Context } from "../../../App";
const Navbar = () => {
  const { setRedirectPath } = useContext(Context);

  return (
    <div>
      <div className={style.wrapper}>
        <div className="logo">
          <Link to={"/"} onClick={() => setRedirectPath("/")}>
            <img src={logo} alt="" />
          </Link>
        </div>
        <Link to={"/add"} onClick={() => setRedirectPath("add")}>
          <button className={style.btn_admin}>New</button>
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
