import React from "react";
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import "./GoogleMap.css";

const GoogleMap = () => {
  const defaultState = {
    center: [41.185, 69.128056],
    zoom: 14,
  };
  return (
    <div className="yandex_maps">
      <YMaps>
        <Map defaultState={defaultState} width={"900px"} height={"50vh"}>
          <Placemark geometry={[41.185, 69.128056]} />
        </Map>
      </YMaps>
    </div>
  );
};

export default GoogleMap;
