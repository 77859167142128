import React, { useRef, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { motion } from "framer-motion";
import "./SearchComponent.css";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
const SearchComponent = (props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [results, setResults] = useState([]);

  const [isFocused, setIsFocused] = useState(false);

  const [isMenuOpen, setIsMenuOpen] = useState(true);

  const closeMenu = () => setIsMenuOpen(false);

  const fetchData = (e) => {
    if (e !== "") {
      fetch(
        "https://whispering-journey-22504-df61e4fdbd1a.herokuapp.com/api/products/search/" +
          `${e.trim()}`
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setResults([...[], ...data]);
          console.log(data);
        });
    }
  };

  const getResults = (str) => {
    setValue(str);
    if (str != null) {
      fetchData(str);
    }
  };

  const toggleFocus = () => {
    setIsFocused((focus) => !focus);
  };

  return (
    <div className="search_wrapper">
      <input
        className="input-search"
        placeholder={t("search")}
        type="text"
        value={value}
        onChange={(e) => getResults(e.target.value)}
      />
      <button className="btn-search" onClick={toggleFocus}>
        <BiSearch />
      </button>
      <motion.div className="resultlist">
        {value === "" ? null : (
          <ul className={`${isMenuOpen}`}>
            {results.map((res) => (
              <Link
                to={`/productInfo/${res.id}`}
                style={{ textDecoration: "none", color: "black" }}
              >
                <li key={res.id} className="list-item" onClick={closeMenu}>
                  {res.name}
                </li>
              </Link>
            ))}
          </ul>
        )}
      </motion.div>
    </div>
  );
};

export default SearchComponent;
