import React, { useState } from "react";
import style from "./Add.module.css";
import axios from "axios";
import { FileUploader } from "react-drag-drop-files";
const Add = () => {
  function validateForm() {
    if (name.length == 0) {
      alert("Invalid Form, Name can not be empty");
      return false;
    }

    if (description.length == 0) {
      alert("Invalid Form, Description can not be empty");
      return false;
    }

    if (solidContent.length == 0) {
      alert("Invalid Form, Solid Content can not be empty");
      return false;
    }

    if (ph.length == 0) {
      alert("Invalid Form, pH can not be empty");
      return false;
    }
    if (viscosity.length == 0) {
      alert("Invalid Form, Viscosity can not be empty");
      return false;
    }
    if (temperatures.length == 0) {
      alert("Invalid Form, Temperatures can not be empty");
      return false;
    }
    // if (file.length === null) {
    //   alert("Invalid Form, File can not be empty");
    //   return false;
    // }
    // if (photo.length === null) {
    //   alert("Invalid Form, Image can not be empty");
    //   return false;
    // }
    // if (photo2.length === null) {
    //   alert("Invalid Form, Image can not be empty");
    //   return false;
    // }
    return true;
  }
  const fileTypes = ["PDF"];
  const photoTypes = ["PNG"];
  const [file, setFile] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [photo2, setPhoto2] = useState(null);

  const handleFileChange = async (file) => {
    console.log(file);
    const token = localStorage.getItem("token");
    const theToken = token.substring(1, token.length - 1);
    const options = {
      headers: {
        "content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ` + `${theToken}`,
      },
    };
    const formData = new FormData();
    formData.append("file", file);
    await axios
      .post(
        "https://whispering-journey-22504-df61e4fdbd1a.herokuapp.com/api/products/file",
        formData,
        options
      )
      .then((response) => {
        setFile(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handlePhotoChange = async (file) => {
    const token = localStorage.getItem("token");
    const theToken = token.substring(1, token.length - 1);
    const options = {
      headers: {
        "content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ` + `${theToken}`,
      },
    };
    const formData = new FormData();
    formData.append("file", file);
    await axios
      .post(
        "https://whispering-journey-22504-df61e4fdbd1a.herokuapp.com/api/products/file",
        formData,
        options
      )
      .then((response) => {
        setPhoto(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handlePhoto2Change = async (file) => {
    const token = localStorage.getItem("token");
    const theToken = token.substring(1, token.length - 1);
    const options = {
      headers: {
        "content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ` + `${theToken}`,
      },
    };
    const formData = new FormData();
    formData.append("file", file);
    await axios
      .post(
        "https://whispering-journey-22504-df61e4fdbd1a.herokuapp.com/api/products/file",
        formData,
        options
      )
      .then((response) => {
        setPhoto2(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [solidContent, setSolidContent] = useState("");
  const [ph, setpH] = useState("");
  const [viscosity, setViscosity] = useState("");
  const [temperatures, setTemperatures] = useState("");

  const addPost = async () => {
    console.log(name, description, solidContent, ph, viscosity, temperatures);
    console.log(2);
    // if (validateForm()) {
    //   return;
    // }

    let token = localStorage.getItem("token");
    const theToken = token.substring(1, token.length - 1);
    console.log(theToken);
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ` + `${theToken}`,
      },
    };
    console.log(options);
    await axios
      .post(
        "https://whispering-journey-22504-df61e4fdbd1a.herokuapp.com/api/products",
        {
          name: name.toString(),
          photoPath: `${photo},${photo2}`,
          filePath: file,
          description: description,
          feature: {
            chemicalC: ph,
            solidContact: solidContent,
            ph: 0,
            viscosity: viscosity,
            tg: temperatures,
          },
        },
        options
      )
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          window.location.href = "/dashboard";
        } else {
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className={style.wrapper_add}>
        <h1>Adding New product</h1>
        <input
          type="text"
          placeholder="Enter name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <div className={style.drag_admin}>
          <div>
            {" "}
            <p>File Drag & Drop</p>
            <FileUploader
              handleChange={handleFileChange}
              name="file"
              value={file}
              types={fileTypes}
            />
          </div>

          <div>
            {" "}
            <p>First Photo Drag & Drop</p>
            <FileUploader
              handleChange={handlePhotoChange}
              name="photo"
              value={photo}
              types={photoTypes}
            />
          </div>
          <div>
            {" "}
            <p>Second Photo Drag & Drop</p>
            <FileUploader
              handleChange={handlePhoto2Change}
              name="photo2"
              value={photo2}
              types={photoTypes}
            />
          </div>
        </div>
        <input
          type="text"
          placeholder="Solid Content"
          value={solidContent}
          onChange={(e) => setSolidContent(e.target.value)}
        />
        <input
          type="text"
          placeholder="pH"
          value={ph}
          onChange={(e) => setpH(e.target.value)}
        />
        <input
          type="text"
          placeholder="Viscosity"
          value={viscosity}
          onChange={(e) => setViscosity(e.target.value)}
        />
        <input
          type="text"
          placeholder="Temperatures"
          value={temperatures}
          onChange={(e) => setTemperatures(e.target.value)}
        />
        <input className={style.btn_add} type="submit" onClick={addPost} />
      </div>
    </div>
  );
};

export default Add;
