import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Contact from "../../components/Contact/Contact";
import Footer from "../../components/Footer/Footer";
import { useParams } from "react-router-dom";
import axios from "axios";
import Spinner from "../DoubleBubble/Spinner";
import "./ProductInfo.css";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useTranslation } from "react-i18next";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
const ProductInfo = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  console.log("Fetched data for id:", id);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/farovon.json");
        const productData = response.data.find((item) => item.id === id);

        if (productData) {
          setProduct(productData);
        } else {
          console.error(`Product with id ${id} not found.`);
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    fetchData();
  }, [id]);

  if (!product) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  const buttonVariants = {
    rest: { scale: 1 },
    hover: { scale: 1.1 },
    pressed: { scale: 0.95 },
  };

  const textAnimation = {
    hidden: {
      x: -100,
      opacity: 0,
    },
    visible: (custom) => ({
      x: 0,
      opacity: 1,
      transition: { delay: custom * 0.2 },
    }),
  };

  return (
    <div>
      <Navbar />
      <motion.div
        initial="hidden"
        whileInView="visible"
        className="product_desc"
      >
        <div className="titles">
          <motion.h1 custom={1} variants={textAnimation} className="desc_title">
            {product.name}
          </motion.h1>
          <motion.h3
            custom={2}
            variants={textAnimation}
            className="description"
          >
            {t("description")}
          </motion.h3>
          <motion.p custom={3} variants={textAnimation}>
            {product.description}
          </motion.p>
        </div>
        <div className="info_products">
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            navigation={{ clickable: true }}
            className="slider_images"
            spaceBetween={50}
            slidesPerView={1}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
          >
            <SwiperSlide>
              <motion.img
                initial={{ y: -10 }}
                animate={{ y: 10 }}
                transition={{
                  type: "smooth",
                  repeatType: "mirror",
                  duration: 2,
                  repeat: Infinity,
                }}
                src={product.photoPathBlue}
                alt=""
                custom={4}
                variants={textAnimation}
              />
            </SwiperSlide>
            <SwiperSlide>
              <motion.img
                initial={{ y: -10 }}
                animate={{ y: 10 }}
                transition={{
                  type: "smooth",
                  repeatType: "mirror",
                  duration: 2,
                  repeat: Infinity,
                }}
                src={product.photoPathWhite}
                alt=""
                custom={4}
                variants={textAnimation}
              />
            </SwiperSlide>
          </Swiper>

          <motion.div className="products_information">
            <ul className="data_prod">
              <motion.h2 custom={1} variants={textAnimation}>
                {t("features")}
              </motion.h2>
              <motion.li custom={2} variants={textAnimation}>
                {t("solidContent")}(%±1) : {product.feature.solidContact}
              </motion.li>
              <motion.li custom={3} variants={textAnimation}>
                {t("ph")}: {product.feature.chemicalC}
              </motion.li>
              <motion.li custom={4} variants={textAnimation}>
                {t("viscosity")}(cp) : {product.feature.viscosity}
              </motion.li>
              <motion.li custom={5} variants={textAnimation}>
                <pre>{t("glassTranstionTemp")}</pre>
                Temperatures(°C) : {product.feature.tg}
              </motion.li>
            </ul>
            <div className="btn_product">
              <h6>{product.name}</h6>
              <motion.button
                variants={buttonVariants}
                whileHover="hover"
                whileTap="pressed"
              >
                <a href={product.filePath}>{t("download")}</a>
              </motion.button>
            </div>
          </motion.div>
        </div>
      </motion.div>

      <Contact />
      <Footer />
    </div>
  );
};

export default ProductInfo;
