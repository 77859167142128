import React, { useState } from "react";
import "./Footer.css";
import logo from "../../assets/logo.png";
import { motion } from "framer-motion";
import { t } from "i18next";
const Footer = () => {
  const [email, setEmail] = useState("hello@farovonmasimum.com");
  const [isEmailCopied, setIsEmailCopied] = useState(false);
  const copyText = (text, setIsCopied) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 500);
      })
      .catch((err) => console.error("Copy failed: ", err));
  };
  const textAnimation = {
    hidden: {
      x: -100,
      opacity: 0,
    },
    visible: (custom) => ({
      x: 0,
      opacity: 1,
      transition: { delay: custom * 0.2 },
    }),
  };
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      className="footer_wrapper"
    >
      <div className="footer_info">
        <div className="footer_logo">
          <motion.img
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1.2 }}
            transition={{ duration: 1 }}
            src={logo}
            alt=""
          />
          <p>Farovon Maksimum®</p>
        </div>
        <ul className="footer_links">
          <motion.h1 custom={1} variants={textAnimation}>
            {t("contactUs")}
          </motion.h1>
          <motion.li
            custom={2}
            variants={textAnimation}
            onClick={() => copyText(email, setIsEmailCopied)}
          >
            {t("email")}: {email}
            {isEmailCopied && (
              <span style={{ color: "green" }}> copied to clipboard</span>
            )}
          </motion.li>
          <motion.li custom={3} variants={textAnimation}>
            {t("location")}: 4Р23в, Chantepa, Zangiata, Tashkent Region,
            Uzbekistan
          </motion.li>
          <motion.li
            custom={4}
            variants={textAnimation}
            className="phone_number_list"
          >
            <span className="phone_numbers_list_title">
              {" "}
              {t("phoneNumbers")}:
            </span>
            <ul className="phone_numbers_list">
              <li>+99898 300 33 00</li>
              <li>+998 95 512 33 00</li>
              <li>+998 95 342 33 00</li>
              <li>+998 90 970 50 00</li>
            </ul>
          </motion.li>
        </ul>
      </div>
      <h1>Copyright ©2023 "FAROVON MAKSIMUM" MChJ</h1>
    </motion.div>
  );
};

export default Footer;
