import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import style from "./Main.module.css";
import Spinner from "../../../subcomponents/DoubleBubble/Spinner";
import { Link } from "react-router-dom";
import { Context } from "../../../App";
const Main = () => {
  const { t } = useTranslation();
  const { updateProduct } = useContext(Context);
  const [users, setUsers] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const fetchData = () => {
    fetch(
      "https://whispering-journey-22504-df61e4fdbd1a.herokuapp.com/api/products"
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setUsers(data);
      });
  };
  const handleDeleteProduct = (productId) => {
    const updatedProducts = users.filter((user) => user.id !== productId);

    localStorage.setItem("products", JSON.stringify(updatedProducts));

    let token = localStorage.getItem("token");
    const theToken = token.substring(1, token.length - 1);
    setFilteredProducts(updatedProducts);
    fetch(
      `https://whispering-journey-22504-df61e4fdbd1a.herokuapp.com/api/products/${productId}`,
      {
        headers: {
          Authorization: `Bearer ` + `${theToken}`,
        },
        method: "DELETE",
      }
    )
      .then((response) => {
        if (response.ok) {
          console.log("Product deleted successfully.");
          const updatedAllProducts = users.filter(
            (user) => user.id !== productId
          );
          setUsers(updatedAllProducts);
          console.log(users);
        } else {
          console.error("Failed to delete product.");
        }
      })
      .catch((error) => console.error(error));
  };
  useEffect(() => {
    fetchData();
  }, []);

  if (!users) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  return (
    <div>
      <div className={style.wrapper_admin_products}>
        {users.length > 0 && (
          <div className={style.wrapper_admin}>
            {users.map((user) => (
              <div key={user.id} className={style.adminproduct}>
                <h5>{user.name}</h5>
                <div className={style.admin_btns}>
                  <Link
                    to={`/update/${user.id}`}
                    onClick={() => updateProduct(user.id)}
                  >
                    <button className={style.update_btn}>Update</button>
                  </Link>

                  <button
                    className={style.delete_btn}
                    onClick={() => handleDeleteProduct(user.id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Main;
