import React, { useContext, useState } from "react";
import logo1 from "../../assets/logo.png";
import "./Navbar.css";
import { BiMenu } from "react-icons/bi";
import { BsFillPersonFill } from "react-icons/bs";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { motion } from "framer-motion";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import SearchComponent from "../../subcomponents/SearchComponent/SearchComponent";
import jwt_decode from "jwt-decode";
import { Context } from "../../App";

const Navbar = () => {
  const { setRedirectPath } = useContext(Context);

  const { t, i18n } = useTranslation();
  const [currentDate, setCurrentDate] = useState(getDate());

  const navigate = useNavigate();

  const [showNavMenu, setShowNavMenu] = useState(false);

  async function handleNavigateTransport() {
    await navigate("/Home");
    await window.scrollTo(0, 800);
  }
  function getDate() {
    const today = new Date();
    return today.getTime();
  }
  const textAnimation = {
    hidden: {
      x: -100,
      opacity: 0,
    },
    visible: (custom) => ({
      x: 0,
      opacity: 1,
      transition: { delay: custom * 0.2 },
    }),
  };

  const NavigateTo = () => {
    const token = localStorage.getItem("token");
    if (token) {
      let thetoken = token.substring(1, token.length - 1);
      let decoded = jwt_decode(thetoken);
      let timeStamp = decoded.exp;
      let resultInMilliSeconds = timeStamp * 1000 - currentDate;
      let resultInHours = resultInMilliSeconds / (1000 * 3600);
      if (resultInHours < 24 && resultInHours > 0) {
        setRedirectPath("dashboard");
      } else {
        setRedirectPath("login");
      }
    } else {
      setRedirectPath("login");
    }
  };
  return (
    <div className="wrapper">
      <div className="middle">
        <div className="content">
          <Link
            to={"/"}
            className="logo_image_only"
            onClick={() => setRedirectPath("/")}
          >
            <img src={logo1} alt="" />
          </Link>
          <div className="logo_context">
            <Link to={"/"}>
              <img src={logo1} alt="" />
            </Link>

            <p>Farovon Maksimum®</p>
          </div>
          <motion.ul className="links" initial="hidden" whileInView="visible">
            <Link to={"/"} className="click_link">
              <motion.li custom={1} variants={textAnimation}>
                {t("home")}
              </motion.li>
            </Link>
            <Link to={"/about"} className="click_link">
              <motion.li custom={2} variants={textAnimation}>
                {t("aboutUs")}
              </motion.li>
            </Link>
            <Link to={"/product"} className="click_link">
              <motion.li custom={3} variants={textAnimation}>
                {t("products")}
              </motion.li>
            </Link>
            <Link className="click_link">
              <motion.li
                onClick={() => handleNavigateTransport()}
                custom={4}
                variants={textAnimation}
              >
                {t("contactUs")}
              </motion.li>
            </Link>
            <div className="search_input">
              <SearchComponent />
            </div>
            <div className="lang">
              <p onClick={() => i18n.changeLanguage("en")}>ENG</p>
              <p onClick={() => i18n.changeLanguage("ru")}>RUS</p>
            </div>
            <div className="profile">
              <Link onClick={NavigateTo}>
                <BsFillPersonFill className="profile_btn" />
              </Link>
            </div>
          </motion.ul>
          <div onClick={() => setShowNavMenu(!showNavMenu)} className="menu">
            <BiMenu className="menu_icon" />
          </div>
        </div>

        <div
          className={clsx({
            navMenu: true,
            showMenu: showNavMenu,
          })}
        >
          <ul className="menu_links">
            <Link to={"/"} className="click_link">
              <li> {t("home")}</li>
            </Link>
            <Link to={"/about"} className="click_link">
              <li> {t("aboutUs")}</li>
            </Link>
            <Link to={"/product"} className="click_link">
              <li> {t("products")}</li>
            </Link>
            <Link className="click_link">
              <li onClick={() => handleNavigateTransport()}>
                {t("contactUs")}
              </li>
            </Link>
            <div className="search_input_menu">
              <SearchComponent />
            </div>
            <div className="lang">
              <p onClick={() => i18n.changeLanguage("en")}>ENG</p>
              <p onClick={() => i18n.changeLanguage("ru")}>RUS</p>
            </div>
            <div>
              <Link className="profile" onClick={NavigateTo}>
                <BsFillPersonFill className="profile_btn" />
                <p>{t("logIn")}</p>
              </Link>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
