import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Product from "./components/Product/Product";
import ProductInfo from "./subcomponents/ProductInfo/ProductInfo";
import Dashboard from "./components/Dashboard/Dashboard";
import React, { useEffect, useState } from "react";
import Login from "./subcomponents/Login/Login";
import Add from "./components/admincomponents/Add/Add";
import Update from "./components/admincomponents/Update/Update";

export const Context = React.createContext();

function App() {
  const [isSuccessfull, setIsSuccessFull] = useState();

  const [redirectPath, setRedirectPath] = useState("");

  useEffect(() => {
    setRedirectPath("/");
  }, []);

  return (
    <Context.Provider
      value={{
        isSuccessfull,
        setIsSuccessFull,
        setRedirectPath,
      }}
    >
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/add" element={<Add />} />
          <Route path="/product" element={<Product />} />
          <Route path="/update/:id" element={<Update />} />
          <Route path="/productInfo/:id" element={<ProductInfo />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
        {(isSuccessfull || redirectPath === "dashboard") && (
          <Navigate to="/dashboard" />
        )}
        {redirectPath === "login" && <Navigate to="/login" />}
        {redirectPath === "add" && <Navigate to={"/add"} />}
        {redirectPath === "update" && <Navigate to={"/update:id"} />}
      </div>
    </Context.Provider>
  );
}

export default App;
