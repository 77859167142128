import React, { useState, useEffect } from "react";
import style from "./Update.module.css";
import { FileUploader } from "react-drag-drop-files";
import axios from "axios";
import { useParams, useNavigate } from "react-router";
const Update = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [solidContent, setSolidContent] = useState("");
  const [ph, setph] = useState("");
  const [viscosity, setViscosity] = useState("");
  const [temperatures, setTemperatures] = useState("");
  const [file, setFile] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [photo2, setPhoto2] = useState(null);
  const history = useNavigate();
  const { id } = useParams();
  const fileTypes = ["PDF"];
  const photoTypes = ["PNG"];

  let token = localStorage.getItem("token");
  const theToken = token.substring(1, token.length - 1);
  const loadProducts = async () => {
    const { data } = await axios.get(
      `https://whispering-journey-22504-df61e4fdbd1a.herokuapp.com/api/products/${id}`
    );
    console.log(data.name);
    setName(data.name);
    setDescription(data.description);
    setSolidContent(data.feature.solidContact);
    setph(data.feature.ph);
    setFile(data.filePath);
    setViscosity(data.feature.chemicalC);
    setTemperatures(data.feature.tg);
    setPhoto(data.photopath);
  };
  useEffect(() => {
    loadProducts();
  }, []);
  const updateProductInfo = async () => {
    let formField = new FormData();
    formField.append("name", name);
    formField.append("description", description);
    formField.append("solidContent", solidContent);
    formField.append("ph", ph);
    formField.append("filePath", file);
    formField.append("Temperature", temperatures);
    formField.append("chemicalC", viscosity);
    if (photo !== null) {
      formField.append("image", photo);
    }
    await axios({
      method: "PUT",
      url: `https://whispering-journey-22504-df61e4fdbd1a.herokuapp.com/api/products/${id}`,
      data: formField,
    }).then((response) => {
      console.log(response.data);
      history.push("/");
    });
  };

  const handleFileChange = async (file) => {
    const token = localStorage.getItem("token");
    const theToken = token.substring(1, token.length - 1);
    const options = {
      headers: {
        "content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ` + `${theToken}`,
      },
    };
    const formData = new FormData();
    formData.append("file", file);
    await axios
      .post(
        "https://whispering-journey-22504-df61e4fdbd1a.herokuapp.com/api/products/file",
        formData,
        options
      )
      .then((data) => console.log(data))
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePhotoChange = async (file) => {
    const token = localStorage.getItem("token");
    const theToken = token.substring(1, token.length - 1);
    const options = {
      headers: {
        "content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ` + `${theToken}`,
      },
    };
    const formData = new FormData();
    formData.append("file", file);
    await axios
      .post(
        "https://whispering-journey-22504-df61e4fdbd1a.herokuapp.com/api/products/file",
        formData,
        options
      )
      .then((response) => {
        setPhoto(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePhoto2Change = async (file) => {
    const token = localStorage.getItem("token");
    const theToken = token.substring(1, token.length - 1);
    const options = {
      headers: {
        "content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ` + `${theToken}`,
      },
    };

    const formData = new FormData();
    formData.append("file", file);
    await axios
      .post(
        "https://whispering-journey-22504-df61e4fdbd1a.herokuapp.com/api/products/file",
        formData,
        options
      )
      .then((response) => {
        setPhoto2(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className={style.wrapper_add}>
        <h1>Update product</h1>
        <input
          type="text"
          placeholder="Enter name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <div className={style.drag_admin}>
          <div>
            <p>File Drag & Drop</p>
            <FileUploader
              handleChange={handleFileChange}
              name="file"
              types={fileTypes}
              value={file}
              onChange={(e) => setPhoto(e.target.value)}
            />
          </div>

          <div>
            {" "}
            <p>First Photo Drag & Drop</p>
            <FileUploader
              handleChange={handlePhotoChange}
              name="photo"
              types={photoTypes}
              value={photo2}
            />
          </div>
          <div>
            {" "}
            <p>Second Photo Drag & Drop</p>
            <FileUploader
              handleChange={handlePhoto2Change}
              name="photo2"
              types={photoTypes}
              value={photo}
            />
          </div>
        </div>
        <input
          type="text"
          placeholder="Solid Content"
          value={solidContent}
          onChange={(e) => setSolidContent(e.target.value)}
        />
        <input
          type="text"
          placeholder="pH"
          onChange={(e) => setph(e.target.value)}
          value={ph}
        />
        <input
          type="text"
          placeholder="Viscosity"
          value={viscosity}
          onChange={(e) => setViscosity(e.target.value)}
        />
        <input
          type="text"
          placeholder="Temperatures"
          value={temperatures}
          onChange={(e) => setTemperatures(e.target.value)}
        />
        <input className={style.btn_add} type="submit" />
      </div>
    </div>
  );
};

export default Update;
