import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import "./Login.css";
import axios from "axios";
import { Context } from "../../App";
import { Link } from "react-router-dom";

const Login = () => {
  const { setRedirectPath } = useContext(Context);
  const { setIsSuccessFull } = useContext(Context);

  const [login, setLogin] = useState("");

  const [password, setPassword] = useState("");

  const onSubmit = () => {
    axios
      .post(
        "https://whispering-journey-22504-df61e4fdbd1a.herokuapp.com/api/authenticate",
        {
          username: login,
          password: password,
        }
      )
      .then((data) => {
        if (data.status === 200) {
          localStorage.setItem("token", JSON.stringify(data.data?.id_token));
          setIsSuccessFull(true);
          setRedirectPath("dashboard");
        } else {
          setIsSuccessFull(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Link to={"/"} onClick={() => setRedirectPath("/")}>
        <button className="back_btn">Back</button>
      </Link>

      <Box
        className="loginform"
        // component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" },
        }}
        // autoComplete="off"
        // onSubmit={onSubmit}
      >
        <h1 className="logintitle">Login</h1>
        <TextField
          id="outlined-basic"
          label="Username"
          variant="outlined"
          className="input_login"
          onChange={(e) => setLogin(e.target.value)}
        />
        <TextField
          id="outlined-basic"
          label="Password"
          variant="outlined"
          className="input_login"
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className="login_btn" onClick={onSubmit}>
          Log in
        </button>
      </Box>
    </div>
  );
};

export default Login;
