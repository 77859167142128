import React, { useState } from "react";
import "./SwiperComponent.css";
import { Swiper, SwiperSlide } from "swiper/react";
import slider1 from "../../assets/slider1.jpg";
import slider2 from "../../assets/slider2.jpg";
import slider3 from "../../assets/slider3.jpg";
import slider4 from "../../assets/slider4.jpg";
import slider5 from "../../assets/slider5.jpg";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { useTranslation, Trans } from "react-i18next";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
const SwiperComponent = () => {
  const { t } = useTranslation();
  return (
    <div className="slider_container">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
      >
        <SwiperSlide>
          <div className="slide">
            <img src={slider1} alt="" />
            <div className="slide_info">
              <h1>{t("coatingSolutions")}</h1>
              <p>{t("coatingSolutionsInfo")}</p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide">
            <img src={slider2} alt="" />
            <div className="slide_info">
              <h1>{t("constructSol")}</h1>
              <p>{t("constructSolInfo")}</p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide">
            <img src={slider3} alt="" />
            <div className="slide_info">
              <h1>{t("textLeath")}</h1>
              <p>{t("textLeathInfo")}</p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide">
            <img src={slider4} alt="" />
            <div className="slide_info">
              <h1>{t("pressureSen")}</h1>
              <p>{t("pressureSenInfo")}</p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide">
            <img src={slider5} alt="" />
            <div className="slide_info">
              <h1>{t("indAdh")}</h1>
              <p>{t("indAdhInfo")}</p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default SwiperComponent;
