import React from "react";
import { FiPhoneCall } from "react-icons/fi";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { HiLocationMarker } from "react-icons/hi";
import Dropdown from "react-bootstrap/Dropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Contact.css";
import { useTranslation } from "react-i18next";
const Contact = () => {
  const { t } = useTranslation();
  return (
    <div className="middle">
      <h1 className="contact_main_title">{t("phoneNumbers")}</h1>
      <div className="contact_wrapper">
        <article className="article_contact">
          <FiPhoneCall className="icon_contact" />
          <div className="phone_dropdown">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic" className="btn_dropdown">
                {t("phoneNumbers")}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="tel:+998 95 512 33 00">
                  +998 95 512 33 00
                </Dropdown.Item>
                <Dropdown.Item href="tel:+998 95 342 33 00">
                  +998 95 342 33 00
                </Dropdown.Item>
                <Dropdown.Item href="tel:+998 90 970 50 00">
                  +998 90 970 50 00
                </Dropdown.Item>
                <Dropdown.Item href="tel:+99898 300 33 00">
                  +99898 300 33 00
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </article>
        <article className="article_contact">
          <BsFillEnvelopeFill className="icon_contact" />
          <div>
            <p className="contact_title">{t("email")}</p>
            <p>hello@farovonmasimum.com</p>
          </div>
        </article>
        <article className="article_contact">
          <HiLocationMarker className="icon_contact" />
          <div>
            <p className="contact_title">{t("location")}</p>
            <p>{t("region")}</p>
          </div>
        </article>
      </div>
    </div>
  );
};

export default Contact;
