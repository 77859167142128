import React, { useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import "./Product.css";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Spinner from "../../subcomponents/DoubleBubble/Spinner";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
const Product = () => {
  const { t } = useTranslation();
  const buttonVariants = {
    rest: { scale: 1 },
    hover: { scale: 1.1 },
    pressed: { scale: 0.95 },
  };
  const [users, setUsers] = useState(null);
  const fetchData = () => {
    fetch("/farovon.json")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setUsers(data);
        console.log(data);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);
  if (!users) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <Navbar />
      <div>
        {users.length > 0 && (
          <div className="product_container">
            {users.map((user) => (
              <div key={user.id} className="product_item">
                <LazyLoadImage
                  loading="lazy"
                  effect="blur"
                  className="img_product"
                  src={user.photoPathBlue}
                  alt="imageProduct"
                />
                <h1>{user.name}</h1>

                <Link to={`/productInfo/${user.id}`}>
                  <motion.button
                    variants={buttonVariants}
                    whileHover="hover"
                    whileTap="pressed"
                    className="btn_products"
                  >
                    {t("quickLook")}
                  </motion.button>
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
      <Contact />
      <Footer />
    </div>
  );
};

export default Product;
