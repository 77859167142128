import React from "react";
import Navbar from "../Navbar/Navbar";
import Main from "../Main/Main";
import SwiperComponent from "../../subcomponents/SwiperComponent/SwiperComponent";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import GoogleMap from "../../subcomponents/GoogleMap/GoogleMap";

const Home = () => {
  return (
    <div>
      <Navbar />
      <Main />
      <SwiperComponent />
      <Contact />
      <GoogleMap />
      <Footer />
    </div>
  );
};

export default Home;
